<!--
@Description: 判断题
 -->

<template>
  <div class="question question-judge-exam" :style="{height:(parent? 'auto':'calc(100vh - 58px - 70px)')}">
    <div class="title">
      <div class="paperTitle">
        <span class="left">
          <span v-if="parent">判断题</span>
          <span v-else>判断题</span>
        </span>
        <span class="right" v-show="examTotal">
          <span class="num">{{this.num}}</span>
          <span class="examTotal">/{{ examTotal }}</span>
        </span>
      </div>
      <div style="display: flex;">
        <span v-html="question.stem"
          class="stem"
          :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*26}px;color:${question.colorMode=='day'?'#333':'#ddd'}`">
        </span>
        <span v-if="!isExercises&&selected&&scoringStatus" style="white-space: nowrap;">（{{ question.score }}分）</span>
      </div>
    </div>

    <div class="content">
      <van-radio-group v-model="selfAnswer" @change="change" :disabled="selected">
        <van-radio name="true" :class="getSingleResult1(selfAnswer, true, question.answer, question)" :checked-color="selfAnswer == question.answer ? '#1A7EE2' : '#1A7EE2'">
          <span :class="getSingleResult(selfAnswer, true, question.answer)" :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*16}px;color:${question.colorMode=='day'?'#333':'#ddd'}`">对</span>
        </van-radio>
        <van-radio name="false" :class="getSingleResult1(selfAnswer, false, question.answer, question)" :checked-color="selfAnswer == question.answer ? '#1A7EE2' : '#1A7EE2'">
          <span :class="getSingleResult(selfAnswer, false, question.answer)" :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*16}px;color:${question.colorMode=='day'?'#333':'#ddd'}`">错</span>
        </van-radio>
      </van-radio-group>
    </div>
     <!-- 答题解析区 -->
     <answerArea
     ref="answerArea"
     v-if="selected"
     :question='question'
     :isExercises='isExercises'
     :remainTime="scoringStatus?'预览':''"
   ></answerArea>
    <!-- <div class="infoContent" v-if="selected">
      <div class="info selfAnswer" >
        <span class="infoTitle">我的答案：</span><span style="display: inline;">{{ question.selfAnswer ? (JSON.parse(question.selfAnswer)?'对':'错') : '未答题' }}</span>
      </div>
      <div class="info answer" >
        <span class="infoTitle underline">参考答案：{{ JSON.parse(question.answer) ? '对':'错' }}</span>
      </div>
      <div class="info analysis">
        <span class="infoTitle">答案解析：</span><span class="quesion-analysis" v-html="question.analysis || '无'" />
      </div>
    </div> -->
  </div>
</template>

<script>
import { Toast } from 'vant'
import answerArea from '../answerArea'
export default {
  components: {
    answerArea
  },
  name: 'Judge',
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    selected: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    parent: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    // 已批阅true 未批阅false
    scoringStatus: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    return {
      selfAnswer: this.question.selfAnswer ? this.question.selfAnswer : '',
      isSubmited: !!this.question.selfAnswer,
      result: ''
    }
  },
  watch: {
    question: {
      deep: true,
      handler (newV, oldV) {
        if (oldV.selfAnswer !== newV.selfAnswer || oldV.selfAnswer === null) {
          if (newV.selfAnswer == null) {
            this.selfAnswer = ''
          }
        }
      }
    }
  },
  computed: {
    isRight () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) { // 未作答
          return true
        } else {
          return String(selfAnswer) === String(answer)
        }
      }
    },
    hasAnswered () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) { // 未作答
          return true
        }
      }
    },
    getSingleResult () {
      return function (selfAnswer, name, answer) {
        let result = ''
        if (this.selected) {
          // if (this.isExercises) {
          //   result = String(selfAnswer) === String(name) ? (String(selfAnswer) === String(answer) ? 'right' : '') : ''
          // } else {
          // }
          result = String(selfAnswer) === String(name) ? (String(selfAnswer) === String(answer) ? 'right' : 'error') : ''
          return result
        } else {
          result = String(selfAnswer) === String(name) ? 'select' : ''
          return result
        }
      }
    },
    getSingleResult1 () {
      return function (selfAnswer, name, answer, question) {
        let result = question.fontSizeMultiple == 2 ? 'margin' : ''
        if (this.selected) {
          // if (this.isExercises) {
          //   result = String(selfAnswer) === String(name) ? (String(selfAnswer) === String(answer) ? 'right' : '') : ''
          // } else {
          // }
          result = String(selfAnswer) === String(name) ? (String(selfAnswer) === String(answer) ? 'right_hover' : 'error_hover') : ''
          return result
        } else {
          result = String(selfAnswer) === String(name) ? 'select_hover' : ''
          return result
        }
      }
    }
  },
  methods: {
    change (e) {
      // this.isSubmited = false
      console.log(this.selfAnswer)
      const obj = {
        ...this.question,
        num: this.num,
        questionId: this.question.id,
        selfAnswer: this.selfAnswer
      }
      if (!obj.selfAnswer) {
        Toast({
          message: '请选择或填写答案'
        })
        return
      }
      // this.isSubmited = true
      this.$emit('change', obj)
    }
    // submit () {
    //   console.log(this.selfAnswer)
    //   let obj = {
    //     ...this.question,
    //     questionId: this.question.id,
    //     selfAnswer: this.selfAnswer
    //   }
    //   if (!obj.selfAnswer) {
    //     Toast({
    //       message: '请选择或填写答案'
    //     })
    //     return
    //   }
    //   this.isSubmited = true
    //   this.$emit('change', obj)
    // }
  }
}
</script>

<style lang="scss">
  .question-judge-exam {
    .stem {
      display: block;
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 70vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        max-width: 100%;
        vertical-align: middle;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .question {
    width: 93.07%;
    height: auto !important;
    box-sizing: border-box;
    position: relative;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 27px 24px 43px;
    margin: 0 auto;

    overflow: auto;
    .title {
      color: #333;
      font-size: 16px;
      line-height: 26px;
      .kinds {
        display: inline-block;
        line-height: 17px;
        color: #fff;
        text-align: center;
        background: #18a6f3;
        border-radius: 8px 8px 0 8px;
        margin-right: 16px;
        padding: 2px 6px;
        font-size: 12px;
      }
      .score {
        color: #999;
      }
    }
    .paperTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
        span {
          padding: 0 7px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(0deg, #3F63EA, #5579F8);
          border-radius: 3px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 11px;
          color: #FFFFFF;
        }
      }
      .right {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 14px;
        .num {
          color: #4165EC;
        }
        .examTotal {
          color: #333333;
        }
      }
    }
    .img {
      width: 80%;
      height: auto;
      display: block;
      margin: 20px auto;
    }
    .content {
      margin: 20px 0;
      .van-radio {
        margin-bottom: 10px;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        align-items: baseline;
        padding: 10px 12px;
        align-items: center;
        background: #F4F6FA;
        border-radius: 10px;
        border: 1px solid transparent;
        box-sizing: border-box;
        ::v-deep .van-radio__icon{
          height: auto !important;
          display: none;
        }
        ::v-deep .van-radio__label{
          display: flex;
          align-items: center;
          // margin: 5px;
        }
        .select {
          color: #3F63EA  !important;
        }
        .right {
          color: #70b603  !important;
        }
        .error {
          color: #d9001b !important;
        }
      }
      .right_hover {
        border-color: #70b603 !important;
      }
      .error_hover {
        border-color: #d9001b;
      }
      .select_hover {
        border-color: #3869F5;
      }
      .radio {
        display: inline-block;
        // width: 25px;
        // height: 25px;
        // line-height: 25px;
        // margin: 0 10px 0 2px;
        text-align: center;
        // border-radius: 50%;
        // box-shadow: 0px 2px 4px 0 rgba($color: #000000, $alpha: .2);
        // font-size: 15px;
        // color: #333;
        &.right {
          background: #1A7EE2 !important;
          color: #fff;
        }
        &.error {
          background: #ff790d;
          color: #fff;
        }
      }
    }
    .infoContent {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #363840;
      .info.selfAnswer {
        span.infoTitle {
        }
        span {
        }
      }
      .info.answer {
        span.infoTitle.underline {
        }
        span {
        }
      }
      .info.analysis {
        span.infoTitle {
        }
        span.quesion-analysis {
          // display: block;
          display: -webkit-inline-box;
          p {
            margin: 0;
            padding: 0;
            // color: #333 !important;
            // font-size: 16px !important;
            // line-height: 26px !important;
          }
          span {
            // color: #333 !important;
            // font-size: 16px !important;
            // line-height: 26px !important;
          }
          p[block-img] {
            text-align: center;
            img {
              width: 60vw !important;
              height: auto;
              border: 5px;
            }
          }
          img {
            height: 40px;
            vertical-align: middle;
          }
        }
      }
      // .analysis {
      //   margin: 30px 15px 70px;
      //   padding: 14px 18px;
      //   font-size: 15px;
      //   color: #333;
      //   background: #eee;
      //   border-radius: 3px;
      //   line-height: 25px;
      //   overflow: hidden;
      //   word-wrap: break-word;
      //   span {
      //     color: #999;
      //   }
      //   .anly {
      //     text-indent: 30px;
      //   }
      // }

    }
    // .analysis {
    //   margin: 30px 15px 70px;
    //   padding: 14px 18px;
    //   font-size: 15px;
    //   color: #333;
    //   background: #eee;
    //   border-radius: 3px;
    //   line-height: 25px;
    //   overflow: hidden;
    //   word-wrap: break-word;
    //   span {
    //     color: #999;
    //   }
    //   .anly {
    //     text-indent: 30px;
    //   }
    // }
  }
</style>
