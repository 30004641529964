<template>
  <div>
    <van-swipe
      ref="swiper"
      class="my-swipe"
      :initial-swipe="currentIndex"
      :loop="false"
      :show-indicators="false"
      :lazy-render="false"
      @change="onChange"
    >
      <van-swipe-item v-for="(item, index) in examList" :key="index">
        <div class="questionBg"></div>
        <single-choice
          v-if="item.typeId == 1"
          :question="item"
          :examTotal="examTotal"
          :num="index + 1"
          :isExercises="isExercises"
          :selected="selected"
          :scoringStatus="scoringStatus"
          @change="answer"
          ></single-choice>
          <multiple-choice
          v-else-if="item.typeId == 2||item.typeId == 3"
          :question="item"
          :examTotal="examTotal"
          :num="index + 1"
          :selected="selected"
          :isExercises="isExercises"
          :scoringStatus="scoringStatus"
          @change="answer"
          ></multiple-choice>
          <!-- <Uncertain
          v-else-if="item.typeId == 3"
          :question="item"
          :num="index + 1"
          :isExercises="isExercises"
          :scoringStatus="scoringStatus"
          @change="answer"
          ></Uncertain> -->
          <judge
          v-else-if="item.typeId == 4"
          :question="item"
          :examTotal="examTotal"
          :num="index + 1"
          :isExercises="isExercises"
          :selected="selected"
          :scoringStatus="scoringStatus"
          @change="answer"
          ></judge>
          <Fill
          v-else-if="item.typeId == 5"
          :question="item"
          :examTotal="examTotal"
          :num="index + 1"
          :isExercises="isExercises"
          :selected="selected"
          :copyFlag="copyFlag"
          :scoringStatus="scoringStatus"
          @change="answer"
          @upload="upload"
          ></Fill>
          <question-answer
          v-else-if="item.typeId == 6"
          :question="item"
          :examTotal="examTotal"
          :num="index + 1"
          :isExercises="isExercises"
          :queType="queType"
          :selected="selected"
          :copyFlag="copyFlag"
          :scoringStatus="scoringStatus"
          @change="answer"
          @upload="upload"
          ></question-answer>
          <analysis
          v-else-if="item.typeId == 7 || item.typeId == 8"
          :question="item"
          :examTotal="examTotal"
          :num="index + 1"
          :isExercises="isExercises"
          :selected="selected"
          :copyFlag="copyFlag"
          :scoringStatus="scoringStatus"
          @change="answer"
          @upload="upload"
        ></analysis>
        <!-- <compatibility
          v-else-if="item.typeId == 8"
          :question="item"
          :num="index + 1"
          :isExercises="isExercises"
          :selected="selected"
          @change="answer"
        ></compatibility> -->
        <div class="loading" v-else>题目加载中~</div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import SingleChoice from './singleChoice'
import MultipleChoice from './multipleChoice'
import Uncertain from './uncertain'
import Fill from './fill'
import Judge from './judge'
import QuestionAnswer from './questionAnswer'
import Analysis from './analysis'
import Compatibility from './compatibility'
export default {
  components: {
    SingleChoice,
    MultipleChoice,
    Uncertain,
    Fill,
    Judge,
    QuestionAnswer,
    Analysis,
    Compatibility
  },
  props: {
    examList: {
      type: Array,
      default: function () {
        return []
      }
    },
    currentIndex: {
      type: Number,
      default: function () {
        return 0
      }
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    queType: {
      type: String,
      default: function () {
        // work 作业 exam考试 liveWork直播作业
        return 'work'
      }
    },
    selected: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    copyFlag: {
      // 禁止复制粘贴
      type: Boolean,
      default: function () {
        return false
      }
    },
    // 已批阅true 未批阅false
    scoringStatus: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    return {
      result: null
    }
  },
  watch: {
    examList: {
      handler (newV, oldV) {
        // console.log(newV);
        this.examList = newV
        // console.log(this.examList,'examList2');
      // if (newV.selfScore || newV.selfScore == 0) {
      //   this.getScore = newV.selfScore
      // }
      },
      deep: true
    }
  },
  created () {
    // console.log(this.examList,'examList');
  },
  methods: {
    answer (val) {
      this.$emit('question-result', val)
    },
    upload (val) {
      this.$emit('upload', val)
    },
    onChange (e) {
      document.body.click()
      this.$nextTick(() => {
        this.$emit('question-index', e)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
  text-align: center;
}
.questionBg {
  position: absolute;
  width: 85.6%;
  background: #D5DDFB;
  border-radius: 0.266667rem;
  top: 16px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}
.my-swipe {
  // background: blue;
}
.van-swipe-item {
  // max-height: 500px;
  // overflow: auto;
  // background: aqua;
}
</style>
