<!--
 * @Date: 2020-02-06 13:14:33
 * @LastEditors: Please set LastEditors
 * @Author: xw
 * @LastEditTime: 2021-06-17 14:23:25
 * @Description: 单选题
 -->
<template>
  <div class="question question-children-single-exam">
    <div class="title">
      <div>
        <span class="kinds">单选题</span>
        <span class="stem" v-html="(question.stem)" :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*26}px;color:${question.colorMode=='day'?'#333':'#ddd'}`"></span>
      </div>
    </div>
    <div class="content">
      <van-radio-group v-model="userAnswer" @change="singleChoice">
        <van-radio v-for="(item, index) in formatConversion(question.optionContents)" :key="index" :name="item.name">
          <span slot="icon" class="radio" :class="{ 'right': item.name === userAnswer }" :style="`font-size:${question.fontSizeMultiple*16}px;color:${question.colorMode=='day'?'#333':'#ddd'};border:${question.colorMode=='day'?'none':'1px solid #ddd'};line-height:${question.fontSizeMultiple*25}px;height:${question.fontSizeMultiple*25}px;width:${question.fontSizeMultiple*25}px;`">{{ item.name }}</span>
          <span
          v-if="!!item.content"
          class="option-content" :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*26}px;color:${question.colorMode=='day'?'#333':'#ddd'}`" :class="{ 'right': item.name === userAnswer }" v-html="item.content"></span>
        </van-radio>
      </van-radio-group>
    </div>
    <!-- <div style="text-align: center;">
      <van-button
        v-if="!isSubmited"
        class="submit"
        round size="small"
        color="#18A6F3"
        type="info"
        @click="submit">提 交</van-button>
      <van-button
        v-else
        class="submit"
        round size="small"
        color="#aaa"
        type="info"
        @click="submit">已提交</van-button>
    </div> -->
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  mounted () {

  },
  data () {
    return {
      userAnswer: this.question.userAnswer,
      isSubmited: !!this.question.userAnswer
    }
  },
  computed: {
    getSingleResult () {
      return function (userAnswer, name, answer) {
        let result = userAnswer === name ? 'right' : ''
        return result
      }
    },
    formatConversion () {
      return function (str) {
        if (str) {
          return JSON.parse(str)
        } else {
          return []
        }
      }
    }
  },
  methods: {
    singleChoice (e) {
      // this.isSubmited = false
      // console.log(e)
      // let obj = {
      //   ...this.question,
      //   questionId: this.question.id,
      //   userAnswer: e
      // }
      // this.$emit('change', obj)
      console.log(this.userAnswer)
      let obj = {
        ...this.question,
        questionId: this.question.id,
        userAnswer: this.userAnswer
      }
      if (!obj.userAnswer) {
        Toast({
          message: '请选择或填写答案'
        })
        return
      }
      this.$emit('change', obj)
      // this.isSubmited = true
    }
    // submit () {
    //   console.log(this.userAnswer)
    //   let obj = {
    //     ...this.question,
    //     questionId: this.question.id,
    //     userAnswer: this.userAnswer
    //   }
    //   if (!obj.userAnswer) {
    //     Toast({
    //       message: '请选择或填写答案'
    //     })
    //     return
    //   }
    //   this.$emit('change', obj)
    //   this.isSubmited = true
    // }
  }
}
</script>

<style lang="scss">
  .question-children-single-exam {
    .stem {
      display: block;
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 70vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        height: 40px;
        vertical-align: middle;
      }
    }
    .option-content {
      p {
        margin: 0;
        padding: 0;
        display: inline;
        img{
          display: block;
        }
        // color: #333 !important;
        // font-size: 15px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 15px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: left;
        img {
          width: 30vw !important;
          height: auto;
        }
      }
      img {
        height: 40px;
        vertical-align: middle;
      }
    }
    .quesion-analysis {
      display: block;
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 60vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        height: 40px;
        vertical-align: middle;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .question {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 0px 15px 0px;
    .title {
      color: #333;
      font-size: 16px;
      line-height: 26px;
      .kinds {
        display: inline-block;
        line-height: 17px;
        color: #FFA106;
        border:1px solid rgba(245,166,35,1);
        background:rgba(245,166,35,0.15);
        text-align: center;
        border-radius: 8px 8px 0 8px;
        margin-right: 16px;
        padding: 2px 6px;
        font-size: 12px;
      }
      .score {
        color: #999;
      }
    }
    .img {
      width: 80%;
      height: auto;
      display: block;
      margin: 20px auto;
    }
    .content {
      margin-top: 30px;
      margin-bottom: 30px;
      .van-radio {
        margin-bottom: 15px;
        color: #333;
        min-height: 38px;
        align-items: baseline;
        .right {
          color: #18A6F3;
        }
        .error {
          color: #ff790d;
        }
      }
      .radio {
        display: inline-block;
        width: 25px;
        height: 25px;
        line-height: 25px;
        margin: 0 10px 0 2px;
        text-align: center;
        border-radius: 50%;
        box-shadow: 0px 2px 4px 0 rgba($color: #000000, $alpha: .2);
        font-size: 15px;
        color: #333;
        &.right {
          background: #18A6F3;
          color: #fff!important;
        }
      }
    }
    .analysis {
      margin: 30px 15px 70px;
      padding: 14px 18px;
      font-size: 15px;
      color: #333;
      background: #eee;
      border-radius: 3px;
      line-height: 25px;
      overflow: hidden;
      word-wrap: break-word;
      span {
        color: #999;
      }
      .anly {
        text-indent: 30px;
      }
    }
  }
</style>
