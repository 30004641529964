<!--
 * @Author: xwen
 * @Date: 2020-02-27 09:43:37
 * @LastEditTime: 2021-03-17 17:11:59
 * @LastEditors: Please set LastEditors
 * @Description: 填空题
 -->
<template>
  <div class="question question-fill-exam" :style="{height:(parent? 'auto':'calc(100vh - 58px - 70px)')}">
    <div class="title">
      <div class="paperTitle">
        <span class="left">
          <span v-if="parent">填空题</span>
          <span v-else>填空题</span>
        </span>
        <span class="right" v-show="examTotal">
          <span class="num">{{this.num}}</span>
          <span class="examTotal">/{{ examTotal }}</span>
        </span>
      </div>
      <div style="display: flex;">
        <span v-html="questionChild.stem"
        class="stem"
        :style="`font-size:${questionChild.fontSizeMultiple*16}px;line-height:${
          questionChild.fontSizeMultiple*26
          }px;color:${questionChild.colorMode=='day'?'#333':'#ddd'}`"></span>
        <span v-if="!isExercises&&selected&&scoringStatus" style="white-space: nowrap;">（{{ question.score }}分）</span>
      </div>
    </div>
    <div class="content">
      <div class="fieldItem" v-for="(item, index) in list" :key="index">
        <template v-if="copyFlag">
          <!-- 禁止复制粘贴输入框 -->
          <van-field
            :style="`font-size:${questionChild.fontSizeMultiple*16}px;line-height:${questionChild.fontSizeMultiple*26}px;background: #F4F6FA; border-radius: 10px;`"
            @change="onInput"
            @paste="$common.preventPaste"
            @paste.prevent
            @copy="$common.preventPaste"
            @copy.prevent
            @cut="$common.preventPaste"
            @cut.prevent
            :class="`${questionChild.colorMode=='day'?'day':'night'}`"
            :key="index"
            placeholder="请填写"
            v-model="list[index]"
            :label-width="`${questionChild.fontSizeMultiple*40}px`"
            :disabled="selected" >
          </van-field>
        </template>
        <template v-else>
          <van-field
            :style="`font-size:${questionChild.fontSizeMultiple*16}px;line-height:${questionChild.fontSizeMultiple*26}px;background: #F4F6FA; border-radius: 10px;`"
            @change="onInput"
            :class="`${questionChild.colorMode=='day'?'day':'night'}`"
            :key="index"
            placeholder="请填写"
            v-model="list[index]"
            :label-width="`${questionChild.fontSizeMultiple*40}px`"
            :disabled="selected" >
          </van-field>
        </template>
        <div class="selfImgContent">
          <template v-if="questionChild.selfImgUrls && questionChild.selfImgUrls.length>0">
            <van-image
              v-if="questionChild.selfImgUrls[index]"
              class="img"
              :src="questionChild.selfImgUrls[index]"
              @click="handleUpload('imgClick',index)"
              />
          </template>
            <div class="img" >
              <van-button :disabled="selected" class="affixBtn" size="small" round @click.stop="handleUpload('img',index)">
                <img src="@/assets/img/add.png" alt="" srcset="">
                <template v-if="questionChild.selfImgUrls&&questionChild.selfImgUrls[index]">
                  <span class="msg">
                    (1/1)
                  </span>
                </template>
              </van-button>
            </div>
        </div>
      </div>
        <!-- :label="index === 0 ? '作答:' : ''" -->
    </div>
    <!-- 解析 -->
    <!-- && !hasAnswered(questionChild.selfAnswer, questionChild.answer) -->
    <div v-if="selected " class="analysis">
     <answerArea
     ref="answerArea"
     v-if="selected"
     :question='question'
     :isExercises='isExercises'
     :remainTime="scoringStatus?'预览':''"
   ></answerArea>
      <!-- 参考答案：<span v-html="answerStr"></span>
      <div class="anly">
        解析：<span v-html="questionChild.analysis || '无'"></span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import answerArea from '../answerArea'

/*
 * 去除字符串中的所有空格
 */
function removeAllSpace (str) {
  if (!str || typeof str !== 'string') return str
  return str.replace(/\s+/g, '').replace('&nbsp;', '').replace(/ /g, '')
}
export default {
  components: {
    answerArea
  },
  name: 'Fill',
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    selected: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    parent: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    // 禁止复制粘贴
    copyFlag: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    // 已批阅true 未批阅false
    scoringStatus: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  watch: {
    question: {
      deep: true,
      handler (newV, oldV) {
        // this.questionChild = newV
        console.log('fill', newV, oldV)
        this.$set(this, 'questionChild', JSON.parse(JSON.stringify(newV)))
        if (newV.selfAnswer == null || (oldV.selfAnswer === null && newV.selfAnswer == null)) {
          const list = JSON.parse(oldV.answer)
          if (list.length > 0) {
            const list2 = []
            list.forEach((item, index) => {
              list2.push('')
              if (index === list.length - 1) {
                this.selfAnswer = []
                this.$set(this, 'list', list2)
              }
            })
          }
        }
      }
    }
  },
  computed: {
    answerStr () {
      try {
        const answerArr = JSON.parse(this.questionChild.answer).join(' | ')
        return answerArr
      } catch (e) {
        return this.questionChild.answer
      }
    },
    isRight () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true
        } else {
          return selfAnswer === answer.trim()
        }
      }
    },
    hasAnswered () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true
        }
      }
    }
  },
  data () {
    return {
      questionChild: {},
      selfAnswer: this.question.selfAnswer || '',
      list: this.fillNum(),
      isSubmited: !!this.question.selfAnswer
    }
  },
  created () {
    this.questionChild = this.question
    // console.log(this.questionChild,'this.questionChild');
  },
  methods: {
    onInput () {
      // this.isSubmited = false
      // console.log('list', this.list)
      const isAnswerNull = this.list.every(item => removeAllSpace(item) === '')
      console.log(this.list, 'list')
      if (isAnswerNull) {
        Toast('请选择或填写答案')
        // return
      }
      const obj = {
        ...this.questionChild,
        questionId: this.questionChild.id,
        num: this.num,
        selfAnswer: JSON.stringify(this.list || [])
      }
      console.log(obj, 'obj')
      // this.isSubmited = true
      this.$emit('change', obj)
    },
    fillNum () {
      if (this.isExercises) {
        if (this.question.selfAnswer) {
          // console.log('sdsads');
          return JSON.parse(this.question.selfAnswer)
        } else {
          // console.log('sdsads2');
          const arr = JSON.parse(this.question.answer)
          const arr2 = []
          for (let i = 0; i < arr.length; i++) {
            arr2[i] = ''
          }
          return arr2
        }
      } else {
        // 考试
        if (this.question.selfAnswer) {
          return JSON.parse(this.question.selfAnswer)
        } else {
          let arr = this.question.stem.match(/\(\s\)/g)
          const arr3 = this.question.stem.match(/（\s）/g)
          if (arr || arr3) {
            if (!arr) {
              arr = []
            }
            arr.push.apply(arr, arr3)
          }
          const arr2 = []
          for (let i = 0; i < arr.length; i++) {
            arr2[i] = ''
          }
          return arr2
        }
      }
    },
    handleUpload (str, idx) {
      const obj = {
        uploadType: str,
        num: this.num,
        fillIdx: idx,
        fillNum: this.list.length,
        questionId: this.questionChild.id,
        ...this.questionChild
      }
      this.$emit('upload', obj)
    }
    // submit () {
    //   console.log('list', this.list)
    //   const isAnswerNull = this.list.every(item => removeAllSpace(item) === '')
    //   if (isAnswerNull) {
    //     Toast('请选择或填写答案')
    //     return
    //   }
    //   let obj = {
    //     ...this.questionChild,
    //     questionId: this.questionChild.id,
    //     selfAnswer: JSON.stringify(this.list)
    //   }
    //   this.isSubmited = true
    //   this.$emit('change', obj)
    // }
  }
}
</script>
<style lang="scss">
  .question-fill-exam {
    .stem {
      display: block;
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 70vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        max-width: 100%;
        vertical-align: middle;
      }
    }
    .quesion-analysis {
      display: block;
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 60vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        height: 40px;
        vertical-align: middle;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .question {
    width: 93.07%;
    height: auto !important;
    box-sizing: border-box;
    position: relative;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 27px 24px 43px;
    margin: 0 auto;

    overflow: auto;
    .title {
      color: #333;
      font-size: 16px;
      line-height: 26px;
      .kinds {
        display: inline-block;
        line-height: 17px;
        color: #fff;
        text-align: center;
        background: #18a6f3;
        border-radius: 8px 8px 0 8px;
        margin-right: 16px;
        padding: 2px 6px;
        font-size: 12px;
      }
      .score {
        color: #999;
      }
    }
    .paperTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
        span {
          padding: 0 7px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(0deg, #3F63EA, #5579F8);
          border-radius: 3px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 11px;
          color: #FFFFFF;
        }
      }
      .right {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 14px;
        .num {
          color: #4165EC;
        }
        .examTotal {
          color: #333333;
        }
      }
    }
    .img {
      width: 80%;
      height: auto;
      display: block;
      margin: 20px auto;
    }
    .content {
      margin-top: 30px;
      margin-bottom: 30px;
      .fieldItem {
        border-radius: 10px;
        margin-bottom: 16px;
        overflow: hidden;
        & .van-button__text {
          display: inline-flex;
          align-items: center;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          img{
            width: 16px;
          height: 18px;
          padding-right: 5px;
          }
        }
        .selfImgContent {
          padding: 10px 6px 16px;
          display: flex;
          grid-gap: 5px;
          flex-wrap: wrap;
          // display: grid;
          // grid-auto-flow: row dense;
          // grid-gap: 22px;
          // grid-template-columns: 1fr 1fr 1fr;
          .img {
            width: calc((100% - 10px) / 3);
            aspect-ratio: 1 / 1;
            border-radius: 6px;
            display: inline-flex;
            overflow: hidden;
            margin: 0;
            button {
              position: relative;
              width: 100%;
              height: 100%;
              background: #F4F6FA;
              border-radius: 6px;
              border: none;
              & .van-button__text {

                img{
                  width: 19px;
                  height: 19px;
                }
                .msg {
                  position: absolute;
                  bottom: 5px;
                  right: 5px;
                }
              }

            }
          }
        }
      }
      ::v-deep .van-cell {

      }
      .submit {
        display: block;
        margin: 20px auto;
      }
      .night{
        ::v-deep .van-field__control{
          color:#ddd;
        }
      }
    }
    .analysis {
      font-size: 0.4rem;
      color: #333;
      border-radius: 0.08rem;
      line-height: 0.666667rem;
      overflow: hidden;
      word-wrap: break-word;
      span {
        color: #999;
      }
      .anly {
        text-indent: 30px;
      }
    }
  }
</style>
