<!--
 * @Date: 2020-02-06 14:19:12
 * @LastEditors: Please set LastEditors
 * @Author: xw
 * @LastEditTime: 2021-03-30 14:38:18
 * @Description: 多选题
 -->
 <template>
  <div
    class="question question-multipleChoice-exam"
    :style="{ height: parent ? 'auto' : 'calc(100vh - 58px - 70px)' }"
  >
    <div class="title">
      <div class="paperTitle">
        <span class="left">
          <span v-if="parent">{{question.typeId==3?'不定项选择题':'多选题'}}</span>
          <span v-else>{{question.typeId==3?'不定项选择题':'多选题'}}</span>
        </span>
        <span class="right" v-show="examTotal">
          <span class="num">{{this.num}}</span>
          <span class="examTotal">/{{ examTotal }}</span>
        </span>
      </div>
      <div style="display: flex;">
        <span
          class="stem"
          v-html="question.stem"
          :style="`font-size:${question.fontSizeMultiple * 16}px;line-height:${
            question.fontSizeMultiple * 26
          }px;color:${question.colorMode == 'day' ? '#333' : '#ddd'}`"
        ></span>
        <span v-if="!isExercises&&selected&&scoringStatus" style="white-space: nowrap;">（{{ question.score }}分）</span>
      </div>
    </div>

    <div class="content">
      <van-checkbox-group
        v-model="selfAnswer"
        @change="multipleChoice"
        :disabled="selected"
      >
        <van-checkbox
          v-for="(item, index) in formatConversion(question.optionContents)"
          shape="square"
          checked-color="#5779F4"
          icon-size="13px"
          :key="index"
          :name="item.name"
          :class="[
              getMultipleResult(selfAnswer, item.name, question.answer),
            ]"
        >
          <div
            :class="[
              'checkbox',
              getMultipleResult(selfAnswer, item.name, question.answer),
            ]"
            :style="`font-size:${question.fontSizeMultiple * 16}px;`"
          >
            {{ item.name }}
          </div>
          <!-- :class="getMultipleResult(selfAnswer, item.name, question.answer)" -->
          <span
            v-if="!!item.content"
            class="option-content"
            v-html="item.content"
            :style="`font-size:${
              question.fontSizeMultiple * 16
            }px;line-height:${question.fontSizeMultiple * 26}px;color:${
              question.colorMode == 'day' ? '#333' : '#ddd'
            }`"
          ></span>
        </van-checkbox>
      </van-checkbox-group>
      <!-- <van-button
        v-if="!isSubmit"
        class="submit"
        round size="small"
        color="#18A6F3"
        type="info"
        @click="submit">提 交</van-button>
        <van-button
          v-if="isSubmit"
          class="submit"
          round size="small"
          color="#aaa"
          type="info"
          @click="submit">已提交</van-button> -->
    </div>
    <!-- 答题解析区 -->
    <answerArea
      ref="answerArea"
      v-if="selected"
      :question='question'
      :isExercises='isExercises'
      :remainTime="scoringStatus?'预览':''"
    ></answerArea>
    <!-- <div class="infoContent" v-if="selected">
      <div class="info selfAnswer">
        <span class="infoTitle">我的答案：</span
        ><span style="display: inline">{{
          question.selfAnswer ? selfAnswer.toString() : "未答题"
        }}</span>
      </div>
      <div class="info answer">
        <span class="infoTitle underline"
          >参考答案：{{ answer ? answer.toString() : "无参考答案" }}</span
        >
      </div>
      <div class="info analysis">
        <span class="infoTitle">答案解析：</span
        ><span class="quesion-analysis" v-html="question.analysis || '无'" />
      </div>
    </div> -->
  </div>
</template>

<script>
import _ from 'lodash'
import { Toast } from 'vant'
import answerArea from '../answerArea'
export default {
  components: {
    answerArea
  },
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    selected: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    parent: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    // 已批阅true 未批阅false
    scoringStatus: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    const selfAnswer = this.question.selfAnswer || ''
    const answer = this.question.answer || ''
    return {
      selfAnswer: selfAnswer ? JSON.parse(selfAnswer) : [], // van-checkbox-group绑定的v-modal为数组
      answer: answer ? JSON.parse(answer) : [], // van-checkbox-group绑定的v-modal为数组
      result: '',
      os: '',
      isSubmit: !!selfAnswer
    }
  },
  watch: {
    question: {
      deep: true,
      handler (newV, oldV) {
        if (oldV.selfAnswer !== newV.selfAnswer && newV.selfAnswer === null) {
          if (newV.selfAnswer == null) {
            this.$set(this, 'selfAnswer', [])
            this.os = 'again'
            // this.selfAnswer = []
          }
        }
      }
    }
  },
  computed: {
    answerStr () {
      try {
        const answerArr = JSON.parse(this.question.answer).join('')
        return answerArr
      } catch (e) {
        return this.question.answer
      }
    },
    isRight () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true
        } else {
          // eslint-disable-next-line
          let answerStr = answer.replace(/[,|\[|\]|\"|\\]/g, "");
          return selfAnswer.join('') === answerStr
        }
      }
    },
    hasAnswered () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true
        }
      }
    },
    getMultipleResult () {
      return function (selfAnswer, name, answer) {
        if (this.selected) {
          let result = answer.includes(name) ? 'right' : 'error'
          if (result == 'error') {
            result = selfAnswer.includes(name) ? 'error' : ''
          }
          return result
        } else {
          const result = selfAnswer.includes(name) ? 'select' : ''
          return result
        }
      }
    },
    formatConversion () {
      return function (str) {
        if (str) {
          return JSON.parse(str)
        } else {
          return []
        }
      }
    }
  },
  methods: {
    multipleChoice (e) {
      console.log(e, '<<<')
      this.result = _.cloneDeep(this.selfAnswer)
      // this.isSubmit = false
      if (this.result.length === 0) {
        Toast({
          message: '请选择或填写答案'
        })
        // return;
      }
      // if (this.result && this.result.length < 2) {
      //   Toast({
      //     message: ''
      //   })
      //   return
      // }
      const obj = {
        ...this.question,
        num: this.num,
        questionId: this.question.id,
        selfAnswer: JSON.stringify(this.result.sort())
      }
      this.$nextTick(() => {
        this.isSubmit = true
      })
      if (this.os !== 'again') {
        this.$emit('change', obj)
      } else {
        this.os = ''
      }
    }
    // submit () {
    //   console.log(this.result, '<<<')
    //   console.log(this.isSubmit, '000')
    //   if (this.result.length === 0) {
    //     Toast({
    //       message: '请选择或填写答案'
    //     })
    //     return
    //   }
    //   // if (this.result && this.result.length < 2) {
    //   //   Toast({
    //   //     message: ''
    //   //   })
    //   //   return
    //   // }
    //   let obj = {
    //     ...this.question,
    //     questionId: this.question.id,
    //     selfAnswer: JSON.stringify(this.result.sort())
    //   }
    //   this.$nextTick(() => {
    //     this.isSubmit = true
    //   })
    //   this.$emit('change', obj)
    // }
  }
}
</script>

<style lang="scss">
.question-multipleChoice-exam {
  .stem {
    // display: block;
    display: contents;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    p {
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
      margin: 0;
      padding: 0;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
    }
    span {
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
    }
    p[block-img] {
      text-align: center;
      img {
        width: 70vw !important;
        height: auto;
        border: 5px;
      }
    }
    img {
      max-width: 100%;
      height: 40px;
      vertical-align: middle;
    }
  }
  .option-content {
    p {
      margin: 0;
      padding: 0;
      // color: #333 !important;
      // font-size: 15px !important;
      // line-height: 26px !important;
    }
    span {
      // color: #333 !important;
      // font-size: 15px !important;
      // line-height: 26px !important;
    }
    p[block-img] {
      text-align: left;
      img {
        width: 30vw !important;
        height: auto;
      }
    }
    img {
      height: 40px;
      vertical-align: middle;
    }
  }
  .quesion-analysis {
    display: block;
    p {
      margin: 0;
      padding: 0;
      // color: #333 !important;
      // font-size: 16px !important;
      // line-height: 26px !important;
    }
    span {
      // color: #333 !important;
      // font-size: 16px !important;
      // line-height: 26px !important;
    }
    p[block-img] {
      text-align: center;
      img {
        width: 60vw !important;
        height: auto;
        border: 5px;
      }
    }
    img {
      height: 40px;
      vertical-align: middle;
    }
  }
}
</style>

<style lang="scss" scoped>
.question {
  width: 93.07%;
  height: auto !important;
  box-sizing: border-box;
  position: relative;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 27px 24px 43px;
  margin: 0 auto;

  overflow: auto;
  .title {
    color: #333;
    font-size: 16px;
    line-height: 26px;
    .kinds {
      display: inline-block;
      line-height: 17px;
      color: #fff;
      text-align: center;
      background: #18a6f3;
      border-radius: 8px 8px 0 8px;
      margin-right: 16px;
      padding: 2px 6px;
      font-size: 12px;
    }
    .score {
      color: #999;
    }
  }
  .paperTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .left {
      span {
        padding: 0 7px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(0deg, #3F63EA, #5579F8);
        border-radius: 3px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 11px;
        color: #FFFFFF;
      }
    }
    .right {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 14px;
      .num {
        color: #4165EC;
      }
      .examTotal {
        color: #333333;
      }
    }
  }
  .img {
    width: 80%;
    height: auto;
    display: block;
    margin: 20px auto;
  }
  .content {
    margin: 20px 0;
    .right {
      border-color: #70b603 !important;
    }
    .van-checkbox {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: 10px;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      align-items: baseline;
      padding: 10px 12px;
      align-items: center;
      background: #F4F6FA;
      border-radius: 10px;
      border: 1px solid transparent;
      box-sizing: border-box;
      ::v-deep .van-radio__icon{
        height: auto !important;
      }
      ::v-deep .checkbox {
        // border-right: 1px solid rgb(239, 239, 239) !important;
        // height: 100% !important;
        // display: flex;
        // align-items: baseline;
      }
      ::v-deep .van-checkbox__label {
        display: flex;
        align-items: center;
      }
      &.select{
        border-color: #3F63EA;
      }
      .select {
        color: #3F63EA!important;
      }
      .right {
        color: #70b603 !important;
      }
      .error {
        color: #d9001b !important;
      }
    }
    .right_hover {
      border-color: #70b603 !important;
    }
    .error_hover {
      border-color: #d9001b;
    }
    .select_hover {
      border-color: #3869F5;
    }
    .checkbox {
      display: inline-block;
      // width: 40px;
      // min-height: 40px;
      // line-height: 25px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin: 0 10px 0 2px;
      // border-radius: 50%;
      // box-shadow: 0px 2px 4px 0 rgba($color: #000000, $alpha: 0.2);
      // font-size: 15px;
      // color: #333;
      // &.right {
      //   background: #1a7ee2;
      //   border-color: #1a7ee2;
      //   color: #fff !important;
      // }
    }
    // .van-checkbox {
    //   margin-bottom: 15px;
    //   color: #333;
    //   min-height: 38px;
    //   align-items:  flex-start;
    //   .right {
    //     color: #18A6F3;
    //   }
    //   .error {
    //     color: #ff790d;
    //   }
    // }
    // .checkbox {
    //   display: inline-block;
    //   width: 25px;
    //   height: 25px;
    //   line-height: 25px;
    //   margin: 0 10px 0 2px;
    //   text-align: center;
    //   border-radius: 50%;
    //   box-shadow: 0px 2px 4px 0 rgba($color: #000000, $alpha: .2);
    //   // font-size: 15px;
    //   // color: #333;
    //   &.right {
    //     background: #18A6F3;
    //     color: #fff!important;
    //   }
    //   &.error {
    //     background: #ff790d;
    //     color: #fff!important;
    //   }
    // }
    .submit {
      display: block;
      margin: 20px auto;
    }
  }
  .infoContent {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    .info.selfAnswer {
      span.infoTitle {
      }
      span {
      }
    }
    .info.answer {
      span.infoTitle.underline {
      }
      span {
      }
    }
    .info.analysis {
      span.infoTitle {
      }
      span.quesion-analysis {
      }
    }
    // .analysis {
    //   margin: 30px 15px 70px;
    //   padding: 14px 18px;
    //   font-size: 15px;
    //   color: #333;
    //   background: #eee;
    //   border-radius: 3px;
    //   line-height: 25px;
    //   overflow: hidden;
    //   word-wrap: break-word;
    //   span {
    //     color: #999;
    //   }
    //   .anly {
    //     text-indent: 30px;
    //   }
    // }
  }
}
</style>
