var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question question-multipleChoice-exam",style:({ height: _vm.parent ? 'auto' : 'calc(100vh - 58px - 70px)' })},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"paperTitle"},[_c('span',{staticClass:"left"},[(_vm.parent)?_c('span',[_vm._v(_vm._s(_vm.question.typeId==3?'不定项选择题':'多选题'))]):_c('span',[_vm._v(_vm._s(_vm.question.typeId==3?'不定项选择题':'多选题'))])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.examTotal),expression:"examTotal"}],staticClass:"right"},[_c('span',{staticClass:"num"},[_vm._v(_vm._s(this.num))]),_c('span',{staticClass:"examTotal"},[_vm._v("/"+_vm._s(_vm.examTotal))])])]),_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"stem",style:(`font-size:${_vm.question.fontSizeMultiple * 16}px;line-height:${
          _vm.question.fontSizeMultiple * 26
        }px;color:${_vm.question.colorMode == 'day' ? '#333' : '#ddd'}`),domProps:{"innerHTML":_vm._s(_vm.question.stem)}}),(!_vm.isExercises&&_vm.selected&&_vm.scoringStatus)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("（"+_vm._s(_vm.question.score)+"分）")]):_vm._e()])]),_c('div',{staticClass:"content"},[_c('van-checkbox-group',{attrs:{"disabled":_vm.selected},on:{"change":_vm.multipleChoice},model:{value:(_vm.selfAnswer),callback:function ($$v) {_vm.selfAnswer=$$v},expression:"selfAnswer"}},_vm._l((_vm.formatConversion(_vm.question.optionContents)),function(item,index){return _c('van-checkbox',{key:index,class:[
            _vm.getMultipleResult(_vm.selfAnswer, item.name, _vm.question.answer),
          ],attrs:{"shape":"square","checked-color":"#5779F4","icon-size":"13px","name":item.name}},[_c('div',{class:[
            'checkbox',
            _vm.getMultipleResult(_vm.selfAnswer, item.name, _vm.question.answer),
          ],style:(`font-size:${_vm.question.fontSizeMultiple * 16}px;`)},[_vm._v(" "+_vm._s(item.name)+" ")]),(!!item.content)?_c('span',{staticClass:"option-content",style:(`font-size:${
            _vm.question.fontSizeMultiple * 16
          }px;line-height:${_vm.question.fontSizeMultiple * 26}px;color:${
            _vm.question.colorMode == 'day' ? '#333' : '#ddd'
          }`),domProps:{"innerHTML":_vm._s(item.content)}}):_vm._e()])}),1)],1),(_vm.selected)?_c('answerArea',{ref:"answerArea",attrs:{"question":_vm.question,"isExercises":_vm.isExercises,"remainTime":_vm.scoringStatus?'预览':''}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }