<template>
  <div class="question-analysis question-analysis-exam">
    <div class="title">
      <!-- <span class="kinds">材料分析题</span> -->
      <div class="paperTitle">
        <span class="left">
          <span>{{questionChild.typeId==7?'材料分析题':'配伍题'}} </span>
        </span>
        <span class="right" v-show="examTotal">
          <span class="num">{{this.num}}</span>
          <span class="examTotal">/{{ examTotal }}</span>
        </span>
      </div>
      <div style="display: flex;">
        <div
          v-html="questionChild.stem"
          class="stem"
          :style="`font-size:${questionChild.fontSizeMultiple * 16}px;line-height:${
            questionChild.fontSizeMultiple * 26
          }px;color:${questionChild.colorMode == 'day' ? '#333' : '#ddd'}`"
        ></div>
        <span v-if="!isExercises&&selected" style="white-space: nowrap;">（{{ question.score }}分）</span>
      </div>
    </div>
    <div
      class="question-tip"
      :style="`font-size:${questionChild.fontSizeMultiple * 16}px;line-height:${
        questionChild.fontSizeMultiple * 26
      }px;color:${questionChild.colorMode == 'day' ? '#333' : '#ddd'}`"
    >
      请认真阅读上述材料，完成以下题目：
    </div>
    <div class="updateScoreMax" v-if="!isExercises&&selected&& scoringStatus">
      <span>总得分：{{ getSum() }}</span>
      <!-- <van-field
        v-model="areaScore"
        type="number"
        label="总得分："
        :disabled='true'
      /> -->
    </div>
    <div class="content" v-for="(item, index) in childrenList" :key="index">
      <single-choice
        v-if="item.typeId == 1"
        :question="item"
        :num="index + 1"
        :isExercises="isExercises"
        :selected="selected"
        :parent="true"
        @change="answer"
      ></single-choice>
      <multiple-choice
        v-else-if="item.typeId == 2 || item.typeId == 3"
        :question="item"
        :num="index + 1"
        :isExercises="isExercises"
        :parent="true"
        :selected="selected"
        @change="answer"
      ></multiple-choice>
      <!-- <Uncertain
        v-else-if="item.typeId == 3"
        :question="item"
        :num="index + 1"
        :isExercises="isExercises"
        :parent="true"
        :selected="selected"
        @change="answer"
      ></Uncertain> -->
      <judge
        v-else-if="item.typeId == 4"
        :question="item"
        :num="index + 1"
        :isExercises="isExercises"
        :parent="true"
        :selected="selected"
        @change="answer"
      ></judge>
      <Fill
        v-else-if="item.typeId == 5"
        :question="item"
        :num="index + 1"
        :isExercises="isExercises"
        :parent="true"
        :selected="selected"
        :copyFlag="copyFlag"
        @change="answer"
        @upload="upload"
      ></Fill>
      <question-answer
        v-else-if="item.typeId == 6"
        :question="item"
        :num="index + 1"
        :isExercises="isExercises"
        :parent="true"
        :selected="selected"
        :copyFlag="copyFlag"
        @change="answer"
        @upload="upload"
      ></question-answer>
    </div>
  </div>
</template>

<script>
import SingleChoice from './singleChoice'
import MultipleChoice from './multipleChoice'
import Uncertain from './uncertain'
import Fill from './fill'
import Judge from './judge'
import QuestionAnswer from './questionAnswer'
export default {
  components: {
    SingleChoice,
    MultipleChoice,
    Uncertain,
    Fill,
    Judge,
    QuestionAnswer
  },
  mounted () {
    console.log(this.questionChild.childrenList, '<<asdf')
  },
  computed: {
    childrenList: function () {
      return this.questionChild.childrenList.filter(
        (item) =>
          item.typeId === 1 ||
          item.typeId === 2 ||
          item.typeId === 3 ||
          item.typeId === 4 ||
          item.typeId === 5 ||
          item.typeId === 6
      )
    }
  },
  watch: {
    question: {
      deep: true,
      handler (newV, oldV) {
        console.log(newV, 'newMax')
        this.$set(this, 'questionChild', newV)
        // if (oldV.selfAnswer !== newV.selfAnswer) {
        //   if (newV.selfAnswer == null) {
        //     this.selfAnswer = ''
        //   }
        // }
      }
    }
  },
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    selected: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    // 禁止复制粘贴
    copyFlag: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    // 已批阅true 未批阅false
    scoringStatus: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    return {
      areaScore: null,
      questionChild: {}
    }
  },
  created () {
    this.questionChild = this.question
  },
  methods: {
    getSum () {
      let sum = 0
      this.questionChild.childrenList.forEach(item => {
        sum += parseFloat(item.selfScore || 0)
      })
      return sum
    },
    answer (val) {
      // console.log(val, 'valQ')
      // console.log(this.questionChild, 'this.questionChild')
      var qMain = this.questionChild
      qMain.childrenList[val.num - 1] = val
      // console.log(qMain, 'qMain')
      this.$emit('change', {
        ...this.questionChild,
        num: this.num,
        miniNum: val.num - 1,
        parentQuestion: this.questionChild
      })
    },
    onChange (e) {
      // this.$nextTick(() => {
      //   this.$emit('questionChild-index', e)
      // })
    },
    upload (val) {
      console.log(val, 'analysis')
      this.$emit('upload', {
        ...val,
        num: this.num,
        miniNum: val.num - 1,
        parentQuestion: this.questionChild
      })
    }
  }
}
</script>
<style lang="scss">
.question-analysis-exam {
  .stem {
    display: block;
    p {
      margin: 0;
      padding: 0;
      color: #333;
      font-size: 16px;
      line-height: 26px;
    }
    span {
      color: #333;
      font-size: 16px;
      line-height: 26px;
    }
    p[block-img] {
      text-align: center;
      img {
        width: 70vw !important;
        height: auto;
        border: 5px;
      }
    }
    img {
      max-width: 100%;
      height: 40px;
      vertical-align: middle;
    }
  }
}
</style>
<style lang="scss" scoped>
.updateScoreMax{
  color: #363840;
  padding-top: 17px;
  font-size: 16px;
    line-height: 16px;
}
.question-analysis {
  .question-tip {
    font-size: 14px;
    color: #b2b2b2;
    margin-top: 40px;
  }
  width: 93.07%;
  height: auto !important;
  box-sizing: border-box;
  position: relative;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 27px 24px 43px;
  margin: 0 auto;

  .title {
    color: #333;
    font-size: 16px;
    line-height: 26px;
    .kinds {
      display: inline-block;
      line-height: 17px;
      color: #fff;
      text-align: center;
      background: #18a6f3;
      border-radius: 8px 8px 0 8px;
      margin-right: 16px;
      padding: 2px 6px;
      font-size: 12px;
    }
    .score {
      color: #999;
    }
  }
  .paperTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .left {
      span {
        padding: 0 7px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(0deg, #3F63EA, #5579F8);
        border-radius: 3px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 11px;
        color: #FFFFFF;
      }
    }
    .right {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 14px;
      .num {
        color: #4165EC;
      }
      .examTotal {
        color: #333333;
      }
    }
  }
  .img {
    width: 80%;
    height: auto;
    display: block;
    margin: 20px auto;
  }
}
</style>
