<template>
  <!-- 填空题、问答题-上传图片和视频-图片预览、视频预览 -->
  <div class="uploadCommon">
    <!-- 操作面板 -->
    <van-action-sheet
      v-model="showAction"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="onSelect"
    />
    <!-- 预览图片 -->
    <van-image-preview v-model="showPreview" :images="previewList" :startPosition="startPosition"></van-image-preview>
    <!-- 预览视频 -->
    <van-dialog v-model:show="showVideo" title="" :showConfirmButton="false" close-on-click-overlay >
      <commonPlay v-if="showVideo" :item="videoInfo" ></commonPlay>
    </van-dialog>
    <!--打开相机-->
    <input style="display: none" type="file" ref="videoDom" accept="image/*" capture="camera" @change="videoChange">

    <!-- 调用相机和相册 -->
    <input style="display: none" type="file" ref="imgDom" accept="image/*" @change="imgChange">
    <input style="display: none" type="file" ref="imgVideoDom" accept="image/*,video/*" @change="multipleChange">

  </div>
</template>
<script>
import commonPlay from './commonPlay.vue'
import uploadAli from '@/api/uploadAli.js'
export default {
  name: 'uploadCommon',
  components: {
    commonPlay
  },
  data () {
    return {
      showAction: false,
      showPreview: false,
      showVideo: false,
      isVideo: false,
      videoInfo: {
        id: '',
        source: '',
        height: '300px'
      },
      moduleName: '',
      previewList: [],
      startPosition: 0,
      actions: [],
      uploadingActions: [
        { name: '拍照', value: 'video' },
        { name: '相册上传', value: 'image' }
      ],
      uploadingBothActions: [
        { name: '拍照', value: 'video' },
        { name: '上传图片/视频', value: 'imageVideo' }
        // { name: '上传视频', value: 'moves' }
      ],
      uploadedActions: [
        { name: '预览', value: 'preview' },
        { name: '删除', value: 'delete' }
      ],
      onlyReadActions: [
        { name: '预览', value: 'preview' }
      ]
    }
  },
  created () {
    this.actions = this.uploadingActions
  },
  methods: {
    showEvent (name) {
      this.moduleName = name
      this.showAction = true
    },
    onSelect (item) {
      if (item.value == 'video') {
        this.$refs.videoDom.click()
      } else if (item.value == 'image') {
        this.$refs.imgDom.click()
      } else if (item.value == 'imageVideo') {
        this.$refs.imgVideoDom.click()
      } else if (item.value == 'moves') {
        this.$refs.imgVideoDom.click()
      } else if (item.value == 'preview') {
        if (this.isVideo) {
          this.showVideo = !this.showVideo
          // this.videoInfo = JSON.parse(JSON.stringify(this.videoInfo))
        } else {
          this.showPreview = !this.showPreview
        }
      } else if (item.value == 'delete') {
        this.$emit('uploadfinish', 'delImg')
      }
    },
    async imgChange () {
      const inputDOM = this.$refs.imgDom
      console.log(this.$refs.imgDom, 'file 组价对象-imgDom')

      const files = inputDOM.files[0]
      console.log(files, 'file-imgDom')
      const fileSize = files.size / 1024 / 1024
      // if(fileSize > 10) {
      //   this.Error('照片不大于10M')
      //   return
      // }
      console.log('开始上传-imgDom')

      const obj = await uploadAli.imgFileUploadOss(files, 'img')
      console.log(obj, '相册985')

      this.$emit('uploadfinish', obj)
      this.$refs.imgDom.value = ''
    },
    // async videoChange() {
    //   // 相机
    //   let inputDOM = this.$refs.imgDom;
    //   let files = inputDOM.files[0];
    //   let fileSize = files.size / 1024 / 1024
    //   // if(fileSize > 10) {
    //   //   this.Error('照片不大于10M')
    //   //   return
    //   // }
    //   if (Math.ceil((files.size)/1024/1024)>249) {
    //     this.Warn('单个视频不得超过250MB!')
    //     return
    //   }
    //   let obj = await uploadAli.videoFileUploadOss(files, this.upLoadType);
    //   // console.log(ossObj);
    //   // if (ossObj) {
    //   //   this.selfVideoUrl = ossObj.excelUrl
    //   //   this.answering(ossObj.excelUrl,'video')
    //   // }
    //   // // console.log(obj,'相册985');
    //   this.$emit('uploadfinish', obj)
    //   // if(this.moduleName == 'face')
    //   //   this.formInline.faceUrl = obj.excelUrl
    //   // else if(this.moduleName == 'IDcardFront')
    //   //   this.formInline.cardIdFront = obj.excelUrl
    //   // else
    //   //   this.formInline.cardIdReverse = obj.excelUrl
    // },
    async multipleChange () {
      // 单上传 图片视频混合上传
      const inputDOM = this.$refs.imgVideoDom
      const file = inputDOM.files[0]
      const ossArr = []
      const isFileType = file.type
      if (isFileType.indexOf('image/') !== -1) {
        if (this.previewList.length >= 5) {
          return this.$toast({
            message: '最多上传5张',
            position: 'top'
          })
        }
        if (Math.ceil((file.size) / 1024 / 1024) > 9) {
          this.Warn('单张图片不得超过10M!')
          return
        }
        // if (this.previewList.length<=5) {
        //   不能同时上传两种类型附件
        // }
        // if (isFileType != 'image/bmp' && isFileType != 'image/jpg' && isFileType != 'image/png' && isFileType != 'image/jpeg') {
        //   this.Warn('只允许上传jpg、jpeg、bmp、png的图片!');
        //   continue
        // }
        const obj = await uploadAli.imgFileUploadOss(
          file,
          'img'
        )
        this.$emit('uploadfinish', obj)
      } else if (isFileType.indexOf('video/') !== -1) {
        if (Math.ceil((file.size) / 1024 / 1024) > 249) {
          this.Warn('视频不得超过250M!')
          return
        }
        // if (isFileType != 'image/bmp' && isFileType != 'image/jpg' && isFileType != 'image/png' && isFileType != 'image/jpeg') {
        //   this.Warn('只允许上传jpg、jpeg、bmp、png的图片!');
        //   continue
        // }
        const obj = await uploadAli.videoFileUploadOss(
          file,
          'video'
        )
        this.$emit('uploadfinish', {
          ...obj,
          type: 'video'
        })
      }
      this.$refs.imgVideoDom.value = ''
    },
    async videoChange () {
      const inputDOM = this.$refs.videoDom
      console.log(this.$refs.videoDom, 'file 组价对象-videoDom')

      const files = inputDOM.files[0]
      console.log(files, 'file 组价对象-videoDom')

      const fileSize = files.size / 1024 / 1024
      if (fileSize > 10) {
        this.Error('照片不大于10M')
        return
      }
      if (this.previewList.length >= 5) {
        return this.$toast({
          message: '最多上传5张',
          position: 'top'
        })
      }
      console.log('开始上传-videoDom')

      const obj = await uploadAli.imgFileUploadOss(files, 'img')
      this.$emit('uploadfinish', obj)
      console.log(obj, '拍照985', files)
      this.$refs.videoDom.value = ''
      // if(this.moduleName == 'face')
      //   this.formInline.faceUrl = obj.excelUrl
      // else if(this.moduleName == 'IDcardFront')
      //   this.formInline.cardIdFront = obj.excelUrl
      // else
      //   this.formInline.cardIdReverse = obj.excelUrl
    }
  }
}
</script>
