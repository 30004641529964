<!--
 * @Description:  问答题
 -->

<template>
  <div class="question question-question-answer-exam" :style="{height:getH()}">
    <div class="title">
      <!-- <span class="kinds">问答题</span> -->
      <div class="paperTitle">
        <span class="left">
          <span v-if="parent">问答题</span>
          <span v-else>{{queType!=='liveWork'?'问答题':''}} </span>
        </span>
        <span class="right" v-show="examTotal">
          <span class="num">{{this.num}}</span>
          <span class="examTotal">/{{ examTotal }}</span>
        </span>
      </div>
      <div style="display: flex;">
        <span
          class="stem"
          v-html="questionChild.stem"
          :style="`font-size:${questionChild.fontSizeMultiple * 16}px;line-height:${
            questionChild.fontSizeMultiple * 26
          }px;color:${questionChild.colorMode == 'day' ? '#333' : '#ddd'}`"
        ></span>
        <span v-if="!isExercises&&selected&&scoringStatus" style="white-space: nowrap;">（{{ question.score }}分）</span>
      </div>
    </div>
    <div class="content">
      <template v-if="copyFlag">
        <van-field
            @paste="$common.preventPaste"
            @paste.prevent
            @copy="$common.preventPaste"
            @copy.prevent
            @cut="$common.preventPaste"
            @cut.prevent
          :style="`font-size:${questionChild.fontSizeMultiple * 16}px;line-height:${
            questionChild.fontSizeMultiple * 26
          }px;color:${questionChild.colorMode == 'day' ? '#333' : '#ddd'};background:#F4F6FA;border-radius: 10px;`"
          @change="inputSubmit"
          v-model="selfAnswer"
          :class="`${questionChild.colorMode == 'day' ? 'day' : 'night'}`"
          autosize
          type="textarea"
          placeholder="请输入答案"
          :label-width="`${questionChild.fontSizeMultiple * 40}px`"
          :disabled="selected"
        >
        </van-field>
      </template>
      <template v-else>
        <van-field
          :style="`font-size:${questionChild.fontSizeMultiple * 16}px;line-height:${
            questionChild.fontSizeMultiple * 26
          }px;color:${questionChild.colorMode == 'day' ? '#333' : '#ddd'};background:#F4F6FA;border-radius: 10px;`"
          @change="inputSubmit"
          v-model="selfAnswer"
          :class="`${questionChild.colorMode == 'day' ? 'day' : 'night'}`"
          autosize
          type="textarea"
          placeholder="请输入答案"
          :label-width="`${questionChild.fontSizeMultiple * 40}px`"
          :disabled="selected"
        >
        </van-field>
      </template>

      <div class="selfImgContent">
          <template v-if="questionChild.selfImgUrls && questionChild.selfImgUrls.length>0">
            <van-image
              v-for="(item, index) in questionChild.selfImgUrls" :key="index"
              class="img"
              :src="item"
              @click="handleUpload('imgClick',index)"/>
          </template>
          <div class="img" >
            <van-button :disabled="selected" size="small" round @click.stop="handleUpload('img')">
              <img src="@/assets/img/add.png" alt="" srcset="">
              <template v-if="questionChild.selfImgUrls&&questionChild.selfImgUrls.length>0">
                <span class="msg">
                  ({{questionChild.selfImgUrls.length}}/5)
                </span>
              </template>
              <template v-else-if="questionChild.selfVideoUrl">
                <span class="msg">
                  ({{questionChild.selfVideoUrl?1:0}}/1)
                </span>
              </template>
            </van-button>
          </div>
      </div>
      <div class="selfImgContent video" v-if="questionChild.selfVideoUrl">
        <div class="img" @click="handleUpload('videoClick')">
          <img class="play" src="@/assets/img/videoPlay.png" alt="" srcset="">
          <img class="bg" src="@/assets/img/video.png" alt="" srcset="">
        </div>
      </div>
    </div>
    <!-- 解析 -->
    <!-- 答题解析区 -->
    <!-- && !hasAnswered(questionChild.selfAnswer, questionChild.answer) -->
    <div
      v-if="selected "
      class="analysis"
    >
    <template v-if="queType=='liveWork'">
      <!-- 直播作业 -->
     <div class="anly">
        评语：<span>{{ questionChild.comments || "暂无" }}</span>
      </div>
    </template>
     <template v-else>
      <answerArea
      ref="answerArea"
      v-if="selected"
      :question='question'
      :isExercises='isExercises'
      :remainTime="scoringStatus?'预览':''"
    ></answerArea>
       <!-- 参考答案：<span v-html="questionChild.answer || '暂无'">{{}}</span>
       <div class="anly">
         解析：<span>{{ questionChild.analysis || "暂无" }}</span>
       </div> -->
     </template>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import answerArea from '../answerArea'

/*
 * 去除字符串中的所有空格
 */
function removeAllSpace (str) {
  if (!str || typeof str !== 'string') return str
  return str.replace(/\s+/g, '').replace('&nbsp;', '').replace(/ /g, '')
}
export default {
  components: {
    answerArea
  },
  name: 'QuestionAnswer',
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    queType: {
      type: String,
      default: function () {
        // work 作业 exam考试 liveWork直播作业
        return 'work'
      }
    },
    selected: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    parent: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    // 禁止复制粘贴
    copyFlag: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    // 已批阅true 未批阅false
    scoringStatus: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    return {
      questionChild: {},
      selfAnswer: this.question.selfAnswer || '',
      isSubmit: !!this.question.selfAnswer
    }
  },
  watch: {
    question: {
      deep: true,
      handler (newV, oldV) {
        console.log(newV, 'ASnewV', oldV)
        this.$set(this, 'questionChild', newV)
        if (oldV.selfAnswer !== newV.selfAnswer || oldV.selfAnswer === null) {
          if (newV.selfAnswer == null) {
            this.selfAnswer = ''
          }
        }
      }
    }
  },
  computed: {
    isRight () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true
        } else {
          return selfAnswer === answer.trim()
        }
      }
    },
    hasAnswered () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true
        }
      }
    }
  },
  created () {
    this.questionChild = this.question
  },
  methods: {
    getH () {
      if (this.queType == 'liveWork') {
        return this.parent ? 'auto' : 'calc(100vh - 70px)'
      } else {
        return this.parent ? 'auto' : 'calc(100vh - 58px - 70px)'
      }
    },
    inputSubmit () {
      const obj = {
        ...this.questionChild,
        num: this.num,
        questionId: this.questionChild.id,
        selfAnswer: this.selfAnswer
      }
      if (!removeAllSpace(obj.selfAnswer)) {
        Toast({
          message: '请选择或填写答案'
        })
      }
      //  else if (this.selfAnswer.length < 5) {
      //   Toast({
      //     message: "请至少填写五个字",
      //   });
      // }
      // this.isSubmit = true
      this.$emit('change', obj)
    },
    handleUpload (str, idx) {
      const obj = {
        uploadType: str,
        num: this.num,
        fillIdx: idx,
        questionId: this.questionChild.id,
        ...this.questionChild
      }
      this.$emit('upload', obj)
    }
    // submit () {
    //   let obj = {
    //     ...this.questionChild,
    //     questionId: this.questionChild.id,
    //     selfAnswer: this.selfAnswer
    //   }
    //   if (!removeAllSpace(obj.selfAnswer)) {
    //     Toast({
    //       message: '请选择或填写答案'
    //     })
    //     return
    //   }
    //   this.isSubmit = true
    //   this.$emit('change', obj)
    // }
  }
}
</script>

<style lang="scss">
.question-question-answer-exam {
  .stem {
    display: block;
    p {
      margin: 0;
      padding: 0;
      color: #333;
      font-size: 16px;
      line-height: 26px;
    }
    span {
      color: #333;
      font-size: 16px;
      line-height: 26px;
    }
    p[block-img] {
      text-align: center;
      img {
        width: 70vw !important;
        height: auto;
        border: 5px;
      }
    }
    img {
      max-width: 100%;
      height: auto;
      min-height: 40px;
      vertical-align: middle;
    }
  }
}
</style>

<style lang="scss" scoped>
.question {
  width: 93.07%;
  height: auto !important;
  box-sizing: border-box;
  position: relative;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 27px 24px 43px;
  margin: 0 auto;

  overflow: auto;
  .title {
    color: #333;
    font-size: 16px;
    line-height: 26px;
    .kinds {
      display: inline-block;
      line-height: 17px;
      color: #fff;
      text-align: center;
      background: #18a6f3;
      border-radius: 8px 8px 0 8px;
      margin-right: 16px;
      padding: 2px 6px;
      font-size: 12px;
    }
    .score {
      color: #999;
    }
  }
  .paperTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .left {
      span {
        padding: 0 7px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(0deg, #3F63EA, #5579F8);
        border-radius: 3px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 11px;
        color: #FFFFFF;
      }
    }
    .right {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 14px;
      .num {
        color: #4165EC;
      }
      .examTotal {
        color: #333333;
      }
    }
  }
  .img {
    width: 80%;
    height: auto;
    display: block;
    margin: 20px auto;
  }
  .content {
    margin: 20px 0;
    overflow: hidden;
    ::v-deep .van-field {
      & .van-cell__value {
        & .van-field__body {
          align-items: flex-end;
        flex-flow: column;
        }
      }
    }
    .selfImgContent {
      padding: 10px 6px 16px;
      display: flex;
      grid-gap: 5px;
      flex-wrap: wrap;
      // display: grid;
      // grid-auto-flow: row dense;
      // grid-gap: 10px;
      // grid-template-columns: 1fr 1fr 1fr;
      &.video {
        .img {
          position: relative;
          .bg {
            width: 100%;
            height: 100%;
          }
          .play {
            position: absolute;
            width: 32px;
            height: 32px;
            top: 35%;
            left: 35%;
          }

        }
      }
      .img {
        width: calc((100% - 10px) / 3);
        aspect-ratio: 1 / 1;
        border-radius: 6px;
        display: inline-flex;
        margin: 0;
        overflow: hidden;
        &:nth-child(3n){
          // margin-right: 0px;
        }
        button {
          width: 100%;
          height: 100%;
          background: #F4F6FA;
          border-radius: 6px;
          border: none;
          & .van-button__text {

            img{
              width: 28px;
              height: 28px;
            }
            .msg {
              position: absolute;
              bottom: 5px;
              right: 5px;
            }
          }
        }
      }
    }
    .submit {
      display: block;
      margin: 20px auto;
    }
    .night {
      ::v-deep .van-field__control {
        color: #ddd;
      }
    }
  }
  .analysis {
    // margin: 30px 15px 70px;
    // padding: 14px 18px;
    font-size: 15px;
    color: #333;
    // background: #eee;
    border-radius: 3px;
    line-height: 25px;
    overflow: hidden;
    word-wrap: break-word;
    span {
      ::v-deep img {
        width: 100%;
      }
      color: #999;
    }
    .anly {
      text-indent: 30px;
    }
  }
}
</style>
