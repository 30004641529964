<!--
 * @Date: 2020-02-06 14:19:12
 * @LastEditors: Please set LastEditors
 * @Author: xw
 * @LastEditTime: 2021-03-30 14:37:58
 * @Description: 多选题
 -->
<template>
  <div class="question question-multipleChoice-exam">
    <div class="title">
      <span class="kinds">不定项选择题</span>
      <span v-if="!isExercises&&selected&&scoringStatus">（{{ question.score }}分）</span>
      
      <span class="stem" v-html="(question.stem)"></span>
    </div>
    
    <div class="content">
      <van-checkbox-group v-model="userAnswer" @change="multipleChoice">
        <van-checkbox v-for="(item, index) in formatConversion(question.optionContents)" :key="index" :name="item.name">
          <span slot="icon"
          v-if="!!item.content"
          :class="['checkbox', getMultipleResult(userAnswer, item.name, question.answer)]">{{ item.name }}</span>
          <span class="option-content"   :class="getMultipleResult(userAnswer, item.name, question.answer)" v-html="item.content"></span>
        </van-checkbox>
      </van-checkbox-group>
      <van-button
        v-if="!isSubmit"
        class="submit"
        round size="small"
        color="#18A6F3"
        type="info"
        @click="submit">提 交</van-button>
        <van-button
          v-if="isSubmit"
          class="submit"
          round size="small"
          color="#aaa"
          type="info"
          @click="submit">已提交</van-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { Toast } from 'vant'
export default {
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    // 已批阅true 未批阅false
    scoringStatus: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    const userAnswer = this.question.userAnswer || ''
    return {
      userAnswer: userAnswer ? JSON.parse(userAnswer) : [], // van-checkbox-group绑定的v-modal为数组
      result: '',
      isSubmit: !!userAnswer
    }
  },
  computed: {
    answerStr () {
      try {
        const answerArr = JSON.parse(this.question.answer).join('')
        return answerArr
      } catch (e) {
        return this.question.answer
      }
    },
    isRight () {
      return function (userAnswer, answer) {
        if (!userAnswer) {
          return true
        } else {
          // eslint-disable-next-line
          let answerStr = answer.replace(/[,|\[|\]|\"|\\]/g, '')
          return userAnswer.join('') === answerStr
        }
      }
    },
    hasAnswered () {
      return function (userAnswer, answer) {
        if (!userAnswer) {
          return true
        }
      }
    },
    getMultipleResult () {
      return function (userAnswer, name, answer) {
        let result = userAnswer.includes(name) ? 'right' : ''
        return result
      }
    },
    formatConversion () {
      return function (str) {
        if (str) {
          return JSON.parse(str)
        } else {
          return []
        }
      }
    }
  },
  methods: {
    multipleChoice (e) {
      console.log(e, '<<<')
      this.result = _.cloneDeep(this.userAnswer)
      this.isSubmit = false
    },
    submit () {
      console.log(this.result, '<<<')
      console.log(this.isSubmit, '000')
      if (!this.result) {
        Toast({
          message: '请选择或填写答案'
        })
        return
      }
      // if (this.result && this.result.length < 2) {
      //   Toast({
      //     message: ''
      //   })
      //   return
      // }
      let obj = {
        ...this.question,
        questionId: this.question.id,
        userAnswer: JSON.stringify(this.result.sort())
      }
      this.$nextTick(() => {
        this.isSubmit = true
      })
      this.$emit('change', obj)
    }
  }
}
</script>

<style lang="scss">
  .question-multipleChoice-exam {
    .stem {
      display: block;
      p {
        margin: 0;
        padding: 0;
      }
      span {
      }
      p[block-img] {
        text-align: center;
        img {
          width: 70vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        max-width: 100%;
        height: 40px;
        vertical-align: middle;
      }
    }
    .option-content {
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 15px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 15px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: left;
        img {
          width: 30vw !important;
          height: auto;
        }
      }
      img {
        height: 40px;
        vertical-align: middle;
      }
    }
    .quesion-analysis {
      display: block;
      p {
        margin: 0;
        padding: 0;
        color: #333 !important;
        font-size: 16px !important;
        line-height: 26px !important;
      }
      span {
        color: #333 !important;
        font-size: 16px !important;
        line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 60vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        height: 40px;
        vertical-align: middle;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .question {
    width: 100%;
    height: calc(100vh - 58px - 30px);
    box-sizing: border-box;
    padding: 17px 15px 15px 15px;
    overflow: auto;
    .title {
      color: #333;
      font-size: 16px;
      line-height: 26px;
      .kinds {
        display: inline-block;
        line-height: 17px;
        color: #fff;
        text-align: center;
        background: #18A6F3;
        border-radius: 8px 8px 0 8px;
        margin-right: 16px;
        padding: 2px 6px;
        font-size: 12px;
      }
      .score {
        color: #999;
      }
    }
    .img {
      width: 80%;
      height: auto;
      display: block;
      margin: 20px auto;
    }
    .content {
      margin-top: 30px;
      .van-checkbox {
        margin-bottom: 15px;
        color: #333;
        min-height: 38px;
        align-items:  flex-start;
        .right {
          color: #18A6F3;
        }
        .error {
          color: #ff790d;
        }
      }
      .checkbox {
        display: inline-block;
        width: 25px;
        height: 25px;
        line-height: 25px;
        margin: 0 10px 0 2px;
        text-align: center;
        border-radius: 50%;
        box-shadow: 0px 2px 4px 0 rgba($color: #000000, $alpha: .2);
        font-size: 15px;
        color: #333;
        &.right {
          background: #18A6F3;
          color: #fff;
        }
        &.error {
          background: #ff790d;
          color: #fff;
        }
      }
      .submit {
        display: block;
        margin: 20px auto;
      }
    }
    .analysis {
      margin: 30px 15px 70px;
      padding: 14px 18px;
      font-size: 15px;
      color: #333;
      background: #eee;
      border-radius: 3px;
      line-height: 25px;
      overflow: hidden;
      word-wrap: break-word;
      span {
        color: #999;
      }
      .anly {
        text-indent: 30px;
      }
    }
  }
</style>
